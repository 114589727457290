import React from "react"
import Layout from '../Layouts/index';
import errorImg from '../images/404.jpg';
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container text-center pb-5 mb-8">

    <h1 className="mt-6 mb-3">Sorry this is still under construction</h1>
    
    <img alt="404" src={errorImg} />
    <p>We're still working on this page... Please bear with us.</p>
    </div>
  </Layout>
)

export default NotFoundPage
