import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import favicon from "../../static/favicon.png";
import PropTypes from "prop-types";

// const SEO = props => (
//   <StaticQuery
//     query={detailsQuery}
//     render={(data) => {
//       const title = props.title || data.site.siteMetadata.title;
//       return (
//         <Helmet
//           htmlAttributes={{
//             lang: 'en',
//           }}
//           title={title}
//           titleTemplate={`%s - ${data.site.siteMetadata.title}`}
//           link={[
//             { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
//           ]}
//         />
//       );
//     }}
//   />
// );

// SEO.defaultProps = {
//   lang: 'en',
//   meta: [],
//   keywords: [],
// };

// export default SEO;

// const detailsQuery = graphql`
//   query DefaultSEOQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `;

function SEO({ description, lang, meta, keywords, title, image }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription
              },
              {
                property: `og:title`,
                content: title
              },
              {
                property: `og:description`,
                content: metaDescription
              },
              {
                property: `og:image`,
                content: `images/cow-large.jpg`
              },
              {
                property: `og:type`,
                content: `website`
              },
              {
                name: `twitter:card`,
                content: `summary`
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author
              },
              {
                name: `twitter:title`,
                content: title
              },
              {
                name: `twitter:description`,
                content: metaDescription
              }
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `)
                    }
                  : []
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [
    'SPKL',
    'Speckle',
    'Speckle Beef',
    'Speckle Park',
    'Premium Beef',
    'Finest Beef in Australia',
    'Australia’s Best Beef',
    'Better than Wagyu'
    ],
  image: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
